import { Injectable } from "@angular/core";
import { SocketEvent } from "@app/enum";
import { getApp } from "@services/index";
import { Log } from "@services/log";
import { SocketService } from "@services/socket.service";
import { AuthService } from "@wearewarp/ng-web";
import { JobStatus } from "@wearewarp/types";
import { BasicAuthUser } from "@wearewarp/types/common/auth";
import {
  EventSocket_JobFollow,
  EventSocket_JobStatusChanged,
  EventSocket_TaskStatusChanged,
  EventSocket_DriverRequestOnlineStatus,
  EventSocket_DriverOnlineStatus,
  EventSocket_DriverFollow,
} from "@wearewarp/types/event";
import { Subject, Subscription } from "rxjs";

export interface LiveTrackingJobStatus {
  jobId: string,
  status: JobStatus,
  isActive: boolean,
}

@Injectable({providedIn: 'root'})
export class DispatchLiveTracking {
  private driverOnlineSubjects: {[driverId: string]: Subject<boolean>} = {};
  private jobStatusSubject: {[jobId: string]: Subject<LiveTrackingJobStatus>} = {}
  private taskStatusSubject: {[taskId: string]: Subject<EventSocket_TaskStatusChanged>} = {};

  private startSubscription: Subscription;

  constructor(private socketService: SocketService, private auth: AuthService) {
  }

  private handleDriverOnline(data: EventSocket_DriverOnlineStatus) {
    Log.d(`handleDriverOnline `, data);
    const isOnline: boolean = data.status == 'online';
    if (!this.driverOnlineSubjects[data.driverId]) {
      this.driverOnlineSubjects[data.driverId] = new Subject();
    }
    this.driverOnlineSubjects[data.driverId].next(isOnline);
  }

  private isMe(authUser: BasicAuthUser): boolean {
    const localAuthUser = getApp().getAuthUser();
    const isUser = authUser.collection == 'users' || authUser.collection == null;     // make sure it is not driver
    return isUser && localAuthUser.id == authUser.id;
  }

  private handleJobStatusChange(data: EventSocket_JobStatusChanged) {
    if (this.isMe(data.authUser)) {
      return;
    }
    const jobId = data.jobId;
    if (!this.jobStatusSubject[jobId]) {
      this.jobStatusSubject[jobId] = new Subject();
    }
    this.jobStatusSubject[jobId].next({
      jobId: jobId,
      isActive: data.isActive,
      status: data.newStatus,
    });
  }

  private handleTaskStatusChange(data: EventSocket_TaskStatusChanged) {
    if (this.isMe(data.authUser)) {
      return;
    }
    const taskId = data.taskId;
    if (!this.taskStatusSubject[taskId]) {
      this.taskStatusSubject[taskId] = new Subject();
    }
    this.taskStatusSubject[taskId].next(data);
  }

  private startIfNeeded() {
    if (this.startSubscription) {
      return;
    }
    const subs = [
      this.socketService.subscribeConnectionStatus(isConnected => this.handleConnectionChange(isConnected)),
      this.socketService.subscribeEvent<EventSocket_DriverOnlineStatus>(SocketEvent.driverOnlineStatus, data => this.handleDriverOnline(data)),
      this.socketService.subscribeEvent<EventSocket_JobStatusChanged>(SocketEvent.jobStatusChange, data => this.handleJobStatusChange(data)),
      this.socketService.subscribeEvent<EventSocket_TaskStatusChanged>(SocketEvent.taskStatusChange, data => this.handleTaskStatusChange(data)),
    ];
    this.startSubscription = new Subscription();
    subs.map(sub => this.startSubscription.add(sub));
  }

  private handleConnectionChange(isConnected: boolean) {
    if (isConnected) {
      // Các trường hợp follow job/driver thực chất là join room.
      // Nếu bị mất kết nối, hoặc là server restart thì cần phải join lại room.
      const jobIds = Object.keys(this.followJobIds);
      for (let jobId of jobIds) {
        this.socketService.emit<EventSocket_JobFollow>(SocketEvent.jobFollow, {data: {jobId}});
      }
      const driverIds = Object.keys(this.followDriverIds);
      for (let driverId of driverIds) {
        this.socketService.emit<EventSocket_DriverFollow>(SocketEvent.driverFollow, {data: {driverId}});
      }
    }
  }

  stop() {
    this.startSubscription.unsubscribe();
    this.startSubscription = undefined;
  }

  private followDriverIds: {[id: string]: 1} = {};  // để re-follow trong trường hợp reconnect
  trackDriverOnline(driverId: string, next: (isOnline: boolean) => void) {
    this.startIfNeeded();
    if (!this.driverOnlineSubjects[driverId]) {
      this.driverOnlineSubjects[driverId] = new Subject();
    }
    const sub = this.driverOnlineSubjects[driverId].subscribe(next);
    this.followDriverIds[driverId] = 1;
    this.socketService.emit<EventSocket_DriverFollow>(SocketEvent.driverFollow, {data: {driverId}});
    this.socketService.emit<EventSocket_DriverRequestOnlineStatus>(SocketEvent.driverRequestOnlineStatus, {data: {driverId}, callback: resp => this.handleDriverOnline(resp)});
    return sub;
  }

  private followJobIds: {[id: string]: 1} = {};  // để re-follow trong trường hợp reconnect
  trackJobStatusChange(jobId: string, next: (data: LiveTrackingJobStatus) => void) {
    this.startIfNeeded();
    if (!this.jobStatusSubject[jobId]) {
      this.jobStatusSubject[jobId] = new Subject();
    }
    const sub = this.jobStatusSubject[jobId].subscribe(next);
    this.followJobIds[jobId] = 1;
    this.socketService.emit<EventSocket_JobFollow>(SocketEvent.jobFollow, {data: {jobId}});
    return sub;
  }

  trackTaskStatusChange(taskId: string, jobId: string, next: (data: EventSocket_TaskStatusChanged) => void) {
    this.startIfNeeded();
    if (!this.taskStatusSubject[taskId]) {
      this.taskStatusSubject[taskId] = new Subject();
    }
    const sub = this.taskStatusSubject[taskId].subscribe(next);
    this.followJobIds[jobId] = 1;
    this.socketService.emit<EventSocket_JobFollow>(SocketEvent.jobFollow, {data: {jobId}});
    return sub;
  }

  async requestDriverOnlineStatus(driverId: string) {
    this.socketService.emit<EventSocket_DriverRequestOnlineStatus>(SocketEvent.driverRequestOnlineStatus, {data: {driverId}, callback: resp => this.handleDriverOnline(resp)});
  }

}