<div class="item-barcode">
    <div class="title right5">Barcode:</div>
    <div class="list-item">
      <ng-container *ngFor="let barcodeData of listBarcodeData">
        <div class="item">
          <span class="right5">{{ barcodeData.value }}</span>
          <nz-tag *ngIf="barcodeData.status" [nzColor]="getStatusColor(barcodeData.status)"
            nz-popover [nzPopoverContent]="tplPopover">
            {{ barcodeData.status }}
          </nz-tag>
        </div>
        <ng-template #tplPopover>
            <div *ngIf="barcodeData.missingReason">{{barcodeData.missingReason}}</div>
            <div class="flex">
                <div class="item">
                    <span class="text-label right5">
                        <span>{{barcodeData.labelScannedBy}}</span>
                    </span>
                    <a *ngIf="barcodeData.link" [href]="barcodeData.link" target="_blank">
                        {{ barcodeData.userName }}
                    </a>
                    <span *ngIf="!barcodeData.link">
                      {{ barcodeData.userName }}
                    </span>
                </div>
                <div class="left5 right5">-</div>
                <div class="item">
                    <span>{{ barcodeData.time }}</span>
                </div>
            </div>
        </ng-template>
      </ng-container>
    </div>
</div>