import { getLinkDashboard } from "@services/routerlink";
import { ResponseWhenBy, ResponseWhenBy_User } from "@wearewarp/types/rest-api/common";

export function getDriver(data: ResponseWhenBy) {
  const mainUser: ResponseWhenBy_User = data.by;
  const linkedDriver: ResponseWhenBy_User = data.linkedEntities.find(it => it.entity == 'drivers');
  return linkedDriver ?? mainUser;
}

export function getName(user: ResponseWhenBy_User, ops?: {includeEntity?: boolean}) {
  if (!user) return '';
  const mapEntity = {
    users: '',
    clients: 'customer',
    carriers: 'carrier',
    drivers: 'driver',
    developers: 'developer',
  };
  const entity = ops.includeEntity ? mapEntity[user.entity] : '';
  if (entity) return `${user.name} (${entity})`;
  return user.name;
}

export function getNameAndLink(user: ResponseWhenBy_User, ops?: {includeEntity?: boolean}) {
  return {name: getName(user, ops), link: getLinkDashboard(user)}
}