import { Component, Input } from "@angular/core";
import { Const } from "@const/Const";
import { Observable } from "rxjs";
import { Utils } from "@services/utils";
import { BaseFormDialog1 } from "@dialogs/base-form-dlg1";
import { InputHelper } from "@services/input-helper";
import { FormControl, Validators } from "@angular/forms";

@Component({
  selector: '[add-item-quick]',
  templateUrl: './index.html',
  styleUrls: [
    '../../../../dialogs/dialogs.scss', 
    '../../../../../styles/form-v2.scss', 
    './style.scss'
  ]
})
export class AddItemQuick extends BaseFormDialog1 {
  @Input() onSave: (data: any) => Observable<any>;
  @Input() onSubmitSucceeded: (resp) => void;
  @Input() onRefreshDetailJob: () => void;
  
  public static get declaration(): FormGroupDeclaration {return {
    id: {label: '', submitReadOnly: true},
    name: {label: 'Commodity/Name', required: false, initialValue: '', placeHolder: 'Commodity'},
    barcodes: {label: 'Barcode Number', type: 'array', initialValue: [], childItem: {label: '', notAcceptEmpty: true} , placeHolder: 'Barcode'},
    barcode: {label: 'Barcode Number', required: true, placeHolder: 'Barcode'},
    qty: {label: 'Qty', type: 'number', required: true, initialValue: 1, placeHolder: 'Qty'},
    qtyUnit: {label: 'Type', initialValue: Const.ShipmentQuantityUnits[0], required: true},
    length: {label: 'Length', type: 'number', required: true, placeHolder: 'L'},
    width: {label: 'Width', type: 'number', required: true, placeHolder: 'W'},
    height: {label: 'Height', type: 'number', required: true, placeHolder: 'H'},
    weightPerUnit: {label: 'Weight Per Unit', type: 'number', required: true, placeHolder: 'Weight'},
    weight: {label: 'Total Weight', type: 'number', required: true, placeHolder: 'Total Weight'}, // total weight
    weightUnit: {label: '', initialValue: Const.WeightUnits[0], required: true, placeHolder: 'Unit'},
    sizeUnit: {label: '', initialValue: Const.SizeUnits[0], required: true, placeHolder: 'Unit'}
  }}
  protected formGroupDeclaration: FormGroupDeclaration = AddItemQuick.declaration;
  public qtyUnits = Const.ShipmentQuantityUnits;
  public sizeUnits = Const.SizeUnits;
  public weightUnits = Const.WeightUnits;


  protected beforeBindModel(model: any) {
    
    return model;
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  onInputKeyPress(event, key) {
    switch (key) {
      case 'qty':
      case 'weightPerUnit':
      case 'length':
      case 'width':
      case 'height':
        return InputHelper.handleInputKeyPressNumberOnly(event);
      case 'itemValue':
        return InputHelper.handleInputKeyPressMoney(event);
      default:
        return super.onInputKeyPress(event, key);
    }
  }

  onInputChanged(event, key) {
    switch (key) {
      case 'qty':
      case 'weightPerUnit':
        this.updateWeight();
        return InputHelper.handleInputChangeNumberOnly(event, <FormControl>this.formInput.get(key));
      case 'weight':
        this.updateWeightPerUnit();
        return InputHelper.handleInputChangeNumberOnly(event, <FormControl>this.formInput.get(key));
      case 'length':
      case 'width':
      case 'height':
        return InputHelper.handleInputChangeNumberOnly(event, <FormControl>this.formInput.get(key));
      case 'itemValue':
        return InputHelper.handleInputChangeMoney(event, <FormControl>this.formInput.get(key), true);
      default:
        return super.onInputChanged(event, key);
    }
  }

  private updateWeightPerUnit() {
    const weight = this.getItemValue('weight');
    const qty = this.getItemValue('qty') ?? 1;
    const weightPerUnit = weight ? Utils.roundNumber(weight / qty, 3) : null;
    let formControl = this.formInput.get('weightPerUnit');
    formControl.enable();
    formControl.setValidators(Validators.required);
    formControl.setValue(weightPerUnit);
    formControl.updateValueAndValidity();
  }

  onModelChange(key) {
    switch (key) {
      case 'qtyUnit':
        let value = this.getItemValue(key);
        // let groupWeight = this.titleGroups.find(it => it.key == 'weight');
        // groupWeight.title = `Weight Per ${value}`;
        if (value == Const.ShipmentItemUnit.truckLoad) {
          // Nếu số lượng item tính bằng cả xe (truck load) thì không bắt buộc nhập kích thước nữa
          for (let key of ['length', 'width', 'height', 'sizeUnit']) {
            this.formGroupDeclaration[key].required = false;
            if (key != 'sizeUnit') {
              this.formGroupDeclaration[key].readOnly = true;
            }
            let formControl = this.formInput.get(key);
            formControl.disable();
            formControl.clearValidators();
            formControl.updateValueAndValidity();
          }
        } else {
          for (let key of ['length', 'width', 'height', 'sizeUnit']) {
            let formControl = this.formInput.get(key);
            this.formGroupDeclaration[key].readOnly = false;
            formControl.enable();
            formControl.updateValueAndValidity();
          }
        }
        break;
      case 'weightPerUnit':
        this.updateWeight();
        break;
    }
  }

  private updateWeight() {
    const weightPerUnit = this.getItemValue('weightPerUnit');
    const qty = this.getItemValue('qty') ?? 1;
    const weight = weightPerUnit ? Utils.roundNumber(weightPerUnit * qty, 0) : null;
    let formControl = this.formInput.get('weight');
    formControl.enable();
    formControl.setValue(weight);
    formControl.updateValueAndValidity();
  }

  onBtnSave() {
    if (!this.needUpdate) return;
    let data: any = this.getFormData_JSON(true);
    if(data.barcode) {
      data.barcodes = [data.barcode];
      delete data.barcode;
    }
    this.onProgress = true;
    this.onSave(data).subscribe(
      resp => {
        this.closeDialog();
        this.onProgress = false;
        this.onRefreshDetailJob();
      }, err => {
        this.showErr(err);
        this.closeDialog();
        this.onProgress = false;
      }
    );
  }

  onBtnCancel() {
    this.closeDialog();
  }
}
