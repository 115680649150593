<div>
  <div class="flex">
    <div class="flex1 code-block">
      <span #pre>{{driverUrl}}</span>
      <i nz-icon nzType="copy" nzTheme="outline" class="copy clickable" style="padding: 8px;" (click)="copyDriverUrl(pre)"></i>
    </div>
    <button nz-button class="btn-send-sms" [nzLoading]="isSendingSMS" (click)="onBtnSendSMS()">Send SMS</button>
  </div>
  <div>
    <textarea nz-input class="msm-content"
      [nzAutosize]="{ minRows: 2, maxRows: 6 }"
      placeholder="SMS Content"
      [(ngModel)]="smsContent"
      [ngModelOptions]="{standalone: true}">
    </textarea>
  </div>
</div>