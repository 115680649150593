<ng-container *ngIf="viewTemplate">
  <ng-container [ngTemplateOutlet]="viewTemplate" [ngTemplateOutletContext]="{time, name, userType, data}"></ng-container>
</ng-container>

<div *ngIf="!viewTemplate" [ngClass]="classContainer">
  <span class="time" [ngClass]="classTime">{{time}}</span>
  <ng-container *ngIf="hasUser">
    <ng-container *ngIf="hyperLink">
      <a class="name" [ngClass]="className" [href]="hyperLink" target="_blank">{{name}} <span *ngIf="!isUser">({{userType}})</span></a>
    </ng-container>
    <ng-container *ngIf="!hyperLink">
      <div class="name" [ngClass]="className" target="_blank">{{name}} <span *ngIf="!isUser">({{userType}})</span></div>
    </ng-container>
  </ng-container>
</div>
