<ng-template [nzModalTitle]>
    <div class="component-header" style="display:flex; justify-content: space-between;">

        <b>Snooze for {{job.code}}</b>
        <div class="modal-close" (click)="closeDialog()">
            <i nz-icon nzType="close"></i>
        </div>
    </div>
</ng-template>
<div>
    <div> 
        <p>

        Please select the time that you want to snooze the <b>"{{trackingTaskName}}"</b> alert. <br/>
        This alert will appear again when the snooze time expires.
    </p>
</div>
    <form [formGroup]="formInput">
        <div class="form-label">Custom: </div>
        <div class="medium warning">
            Note: Maximum Snooze Time is 2 hours
        </div>
        <div nz-row [nzGutter]="{md:16}">
            <div nz-col nzSM="12" nzMd="12" *ngFor="let key of ['hours']" [ngClass]="key">
                <div class="form-label-v2">{{getLabel(key)}}</div>
                <nz-select [formControlName]="key"  style="width: 100%;">
                    <nz-option *ngFor="let number of generateRange(0, 2); let i = index" [nzValue]="number" [nzLabel]="number"></nz-option>

                </nz-select>
            </div>
            <div nz-col nzSM="12" nzMd="12" *ngFor="let key of ['minutes']" [ngClass]="key">
                <div class="form-label-v2">{{getLabel(key)}}</div>
                <nz-select [formControlName]="key"  style="width: 100%;">
                    <nz-option *ngFor="let number of generateRange(0, 59); let i = index" [nzValue]="number" [nzLabel]="number"></nz-option>

                </nz-select>
            </div>
        </div>
        <div class="form-label">Preset: </div>
        <div class="preset">
            <div *ngFor="let item of preset" class="preset-item" (click)="setPreset(item.value)">
                <span>{{item.label}}</span>
            </div>
        </div>
        <div class="top20 bottom20">
            <div class="form-label">Note: </div>
            <textarea [formControlName]="'note'" [placeholder]="getPlaceHolder('note')" nz-input [nzAutosize]="{ minRows: 3, maxRows: 5 }"></textarea>
        </div>
    </form>
</div>
<div form-footer [nzIconCancel]="null" [nzIconOK]="null" (onOK)="onBtnSave()"
labelOK="Snooze" [canClickOK]="true" [canClickCancel]="true" (onCancel)="closeDialog()" style="display: flex; flex-direction: row-reverse; justify-content: flex-end; margin-top: 20px;" ></div>
