import { BaseComponent } from "@abstract/BaseComponent";
import { Component, Input } from "@angular/core";
import { Const } from "@const/Const";
import { BizUtil } from "@services/biz";
import { DateUtil } from "@services/date-utils";
import { DriverAppAnalytics, MobileAppDriverInfo, MobileDeviceInfo } from "@wearewarp/types/data-model";
import { EventSocket_DriverMobileAppStatus } from "@wearewarp/types/event";
import { UnitConverter } from "@wearewarp/universal-libs";
import { DisplayInfo } from "../../dispatch-live-tracking/driver-app-status/component";

@Component({
    selector: 'driver-app-action-history',
    templateUrl: './index.html',
    styleUrls: ['./index.scss'],
})
export class DriverAppActionHistory extends BaseComponent{
  @Input() driverId: string;
  @Input()jobCode: string
  public listData: DriverAppAnalytics[];
  public pending: any = 'Loading...';
  public total: number = 0;
  public isLoading: boolean = true;
  public expandMap: Map<string, boolean> = new Map();
  public get apiUrl(){
    return `${Const.APIV2(Const.APIURI_DRIVER_APP_ANALYTICS)}/${this.driverId}`
  }
  ngOnInit(){
    this.getDataByUrl(this.apiUrl)
  }
  public getLabelTimeLine(item){
    return DateUtil.format(item?.insert?.when, Const.DATETIME_FORMAT_FROM_DB);
  }
  public getAction(item){
    return item?.event;
  }
  onScroll(event: any) {
    if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
      this.getDataMore();
    }
  }
  public getDataMore(){
    if(this.listData.length == this.total){
      this.pending = false;
      return;
    }
    let params: any = {};
    params.skip = this.listData.length;
    let qs = new URLSearchParams(params).toString();
    this.api.GET(`${this.apiUrl}?${qs}`).subscribe(
      (res) => {
        const list = res.data.list_data.map(item => {
          this.expandMap.set(item.id, false);
          return this.generateDriverAppStatusData(item)
        });
        this.listData = [...this.listData,...list];
      }
    )
  }
  public getDataByUrl(url: string){
    this.api.GET(url).subscribe(
      (res) => {
        this.isLoading = false;
        this.total = res.data.total;
        this.listData = res.data.list_data.map(item => {
          this.expandMap.set(item.id, false);
          return this.generateDriverAppStatusData(item)
        });
      }
    )       
  }
  public generateDriverAppStatusData(item: DriverAppAnalytics){
    let driverAppStatus: any = {
      driverId: item.userId,
      sessionId: item?.appData?.sessionId,
      lastKnownLocation: item?.appData?.lastKnownLocation,
      backgroundLocationRunning:item?.appData?.backgroundLocationRunning,
      activeJob: item?.appData?.activeJob,
      openningJobs: item?.appData?.openningJobs, 
      device: item?.appData?.device,
      app: item?.appData?.app,
      countLocationHistoryInLocalDb:item?.appData?.countLocationHistoryInLocalDb
    }
    return { ...item,driverAppStatus: this.buildDisplayData(driverAppStatus)}
  }

  private getActiveJob(data: EventSocket_DriverMobileAppStatus) {
    if (data?.openningJobs && data.openningJobs.length > 0) {
      return data.openningJobs.filter(it => it.isActive)[0];
    } else {
      return data?.activeJob;
    }
  }

  private buildDisplayData(data: EventSocket_DriverMobileAppStatus): DisplayInfo {
    const info: DisplayInfo = {
      platform: data?.device?.platform ?? 'N/A',
      deviceInfo: 'N/A',
      screenSize: 'N/A',
      batteryInfo: 'N/A',
      firstInstall: 'N/A',
      locationPermission: 'N/A',
      backgroundLocation: data?.backgroundLocationRunning == true ? 'running' : 'stop',
      location: 'N/A',
      speed: 'N/A',
      appVersion: 'N/A',
      appState: 'N/A',
      activeRoute: 'N/A',
      assignedRoutes: [],
    };
    const activeJob = this.getActiveJob(data);
    if (activeJob?.code) {
      info.activeRoute = activeJob.code;
      if (activeJob.code != this.jobCode) {
        info.activeRoute += ` (running another route)`;
      }
    }
    const device: MobileDeviceInfo = data?.device;
    if (device) {
      info.deviceInfo = `${device.brand}, ${device.model}, ${device.os} ${device.osVersion}`;
      info.screenSize = `${Math.round(device.screenSizePixel.width)} x ${Math.round(device.screenSizePixel.height)}`;
      if (device.batteryLevel >= 0) {
        info.batteryInfo = `${Math.floor(device.batteryLevel * 100)}%`;
        const tags = [];
        if (device.lowPowerMode) {
          tags.push('low power mode');
        }
        if (device.batteryState == 'charging') {
          tags.push(device.batteryState);
        }
        if (tags.length > 0) {
          info.batteryInfo += ` (${tags.join(', ')})`;
        }
      }
    }
    const gpsData = data?.lastKnownLocation;
    if (gpsData?.latitude && gpsData?.longitude) {
      info.location = `${gpsData.latitude.toFixed(5)}, ${gpsData.longitude.toFixed(5)}`;
    }
    if (gpsData?.speed && gpsData?.speed > 0) {
      // meters per second => miles per hour
      const s = UnitConverter.metersToMiles(gpsData.speed) * 3600;
      info.speed = `${s.toFixed(2)} mph`;
    }
    const app: MobileAppDriverInfo = data?.app;
    if (app) {
      info.appVersion = app.versionText;
      info.firstInstall = `${DateUtil.format(app.firstInstallTimeMs, 'MMM D, YYYY, h:mm a')}`;
      info.locationPermission = app.locationPermission;
      info.appState = app.appState;
    }
    info.assignedRoutes = data?.openningJobs ?? [];
    for (let item of info.assignedRoutes) {
      item.status = BizUtil.getStatusJob(item.status);
      let tags = [];
      if (item.code == this.jobCode) {
        tags.push('this route');
      }
      if (item.isActive) {
        tags.push('active');
      }
      if (tags.length > 0) {
        item.status += ` (${tags.join(', ')})`;
      }
    }
    return info;
  }
  toggleCollapse(id: string){
    let b = this.expandMap.get(id);
    this.expandMap.set(id, !b);
  }
}