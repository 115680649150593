<div *ngIf="!isLoading && listData?.length"  >
    <cdk-virtual-scroll-viewport (scroll)="onScroll($event)" itemSize="91" minBufferPx="850" maxBufferPx="850" style="height: 90vh;overflow-y: auto; overflow-x: hidden;">
    <div style="height: 10px; opacity: 0;"></div>
    <nz-timeline [nzPending]="pending" >
        <ng-container *cdkVirtualFor="let item of listData">
            <nz-timeline-item [nzLabel]="getLabelTimeLine(item)" [nzDot]="dotTemplate">
                <div class="flex" (click)="toggleCollapse(item.id)" style="cursor: pointer;">
                    <div style="font-size: 17px;">{{getAction(item)}}</div>
                    <div class="flex" style="align-items: center;">
                        <i *ngIf="!expandMap.get(item.id)" class="btn-icon icon arrow_drop_down left5"></i>
                        <i *ngIf="expandMap.get(item.id)" class="btn-icon icon arrow_drop_up left5"></i>
                    </div>
                </div>
                <div >
                    <ng-container *ngTemplateOutlet="driverAppStatus; context:{item: item.driverAppStatus, expanded: expandMap.get(item.id)}"></ng-container>
                </div>
            </nz-timeline-item>
        </ng-container>
    </nz-timeline>
</cdk-virtual-scroll-viewport>
</div>
<div *ngIf="!isLoading && (!listData || !listData?.length)">
    <nz-result nzStatus="404"  nzSubTitle="No Data">
      </nz-result>
</div>
<div *ngIf="isLoading" class="nodata" style="flex-direction: column;">
    <div style="text-align: center;">Fetching device information<br/>(it may take some time)</div>
    <i nz-icon nzType="loading" nzTheme="outline" style="margin-top: 20px;"></i>
</div>
<ng-template #dotTemplate>
    <span nz-icon nzType="clock-circle-o" style="font-size: 16px;"></span>
</ng-template>
<ng-template #driverAppStatus let-item ="item" let-expanded = "expanded">
    <div class="title">
        <img class="device-icon {{item.platform}}"/>
        <div class="device-desc">Device {{item.deviceInfo}}</div>
    </div>
    <div class="content" collapse-animation [expanded]="expanded">
        <div class="content-line">
            <div class="label">Screen size</div>
            <div>{{item.screenSize}}</div>
        </div>
    
        <div class="content-line">
            <div class="label">Battery</div>
            <div>{{item.batteryInfo}}</div>
        </div>
        
        <div class="content-line">
            <div class="label">GPS</div>
            <div>{{item.location}}</div>
        </div>
    
        <div class="content-line">
            <div class="label">Speed</div>
            <div>{{item.speed}}</div>
        </div>
    </div>
        
    <div class="title">
        <img class="app-icon" src="assets/img/ic_driver_app.png"/>
        <div class="app-desc">App version {{item.appVersion}}</div>
    </div>
    <div collapse-animation [expanded]="expanded">
        <div class="content">
        
            <div class="content-line">
              <div class="label">Install when</div>
              <div>{{item.firstInstall}}</div>
            </div>
          
            <div class="content-line">
              <div class="label">Location permission</div>
              <div>{{item.locationPermission}}</div>
            </div>
          
            <div class="content-line">
              <div class="label">Background location</div>
              <div class="flex" style="align-items: center;">
                {{item.backgroundLocation}}
              </div>
            </div>
          
            <div class="content-line">
              <div class="label">App state</div>
              <div>{{item.appState}}</div>
            </div>
          
            <!-- <div class="content-line">
              <div class="label">Active route ID</div>
              <div style="font-weight: 500;">{{item.activeRoute}}</div>
            </div> -->
        </div>
          
        <div class="title">
            <i nz-icon nzType="node-index" nzTheme="outline" style="margin-right: 34px;"></i>
            <div class="app-desc">Opening routes ({{item.assignedRoutes.length}})</div>
        </div>
        <div class="content">
            <div class="content-line route" *ngFor="let item of item.assignedRoutes" [ngClass]="{'active': item.isActive == true}">
              <div class="label">{{item.code}}</div>
              <div>{{item.status}}</div>
            </div>
        </div>
    </div>
</ng-template>
