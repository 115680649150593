<div class="shipment-item" *ngIf="displayInfo">
  <div class="row flex-space-between">
    <div class="flex">
      <div class="warp-label item">ID:
        <a target="_blank" class="shipment-warp-id f15 bold" [routerLink]="getRouterLinkWarp(displayInfo.shipment)"
        [fragment]="getRouterLinkFragmentWarp(displayInfo.shipment)">{{displayInfo.shipmentWarpId}}</a>
      </div>
      <div class="item left20">Customer: <span>{{displayInfo.clientName}}</span></div>
    </div>
    <div class="flex">
      <div *ngIf="shouldInitiateReturn() && isEnableUpdateStatus" class="right10">
        <button nz-button nzType="default" nzSize="small" (click)="onBtnInitReturn()">Initiate Return</button>
      </div>
      <button nz-button nzGhost nzType="default" (click)="onBtnUpdateStatus()" class="clickable task-status {{ 'task-status-' + displayInfo.cssClassStatus }}">{{displayInfo.status}}</button>
      <div class="left20">
        <img src="/assets/img/dispatch-icons/file_copy.svg" class="clickable"
          (click)="copyTrackingCode()" nz-tooltip
          nzTooltipTitle="Copy link for sending to customer"/>
        <a [href]="displayInfo.trackingLink" target="_blank" class="tracking-code">{{displayInfo.trackingCode}}</a>
      </div>
      <div *ngIf="isVisiblePOD" class="add-pod left10">
        <button *ngIf="displayInfo.totalPod == 0 && isEnableUpdateStatus" nz-button nzType="default" nzSize="small"
          (click)="onBtnAddPod()">Add POD</button>
        <ng-container *ngIf="displayInfo.totalPod != 0">
          <nz-badge *ngIf="isEnableUpdateStatus" [nzCount]="displayInfo.podNotConfirmed" [nzOverflowCount]="9">
            <button nz-button nzType="default" nzSize="small" (click)="onBtnViewPod()">View POD
              ({{displayInfo.totalPod}})</button>
          </nz-badge>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="row">
    <span class="warp-label right5">{{displayInfo.locationType}}:</span>
    <span *ngIf="displayInfo.locationName" class="medium">{{displayInfo.locationName}},</span>
    <span class="left5">{{displayInfo.addressText}}</span>
  </div>
  <ng-container *ngIf="isShowCanceledReason()">
    <div class="row flex">
      <span class="warp-label">Canceled reason:</span>
      <div class="left10">{{getCanceledReason()}}</div>
    </div>
  </ng-container>
  <div class="row">
    <span class="warp-label right5">Ref No:</span>
    <span *ngIf="!isShowRef()">N/A</span>
    <span *ngIf="isShowRef()">
      <ng-container *ngFor="let ref of displayInfo.refNums">
        <nz-tag [nzColor]="'#F5F5F5'" style="color: #191A23; font-size: 14px; white-space: break-spaces;">{{ ref }}</nz-tag>
      </ng-container>  
    </span>
  </div>
  <div *ngIf="isShowOrderRef()" class="row">
    <span class="warp-label right5">Order Ref No:</span>
    <span *ngIf="!displayInfo.orderRefNums.length">N/A</span>
    <span *ngIf="displayInfo.orderRefNums.length">
      <ng-container *ngFor="let ref of displayInfo.orderRefNums">
        <nz-tag [nzColor]="'#F5F5F5'" style="color: #191A23; font-size: 14px; white-space: break-spaces;">{{ ref }}</nz-tag>
      </ng-container>  
    </span>
  </div>

  <div class="row flex">
    <span class="warp-label">Scheduled:</span>
    <div class="left10">{{displayInfo.scheduled}}</div>
    <div *ngIf="displayInfo.requiresAppointment && displayInfo.appointmentInfo?.from" class="left10 appointment-scheduled">
      Appointment Scheduled
    </div>
    <div *ngIf="displayInfo.requiresAppointment && !displayInfo.appointmentInfo?.from" class="left10 appointment-scheduled">
      Requires Appointment
    </div>
  </div>
  <div class="row flex">
    <span *ngIf="displayInfo.items.length <= 1" class="warp-label">Item: </span>
    <span *ngIf="displayInfo.items.length > 1" class="warp-label">Items ({{displayInfo.items.length}}): </span>
    <div class="left20">
      <div *ngFor="let item of displayInfo.items;let i = index" style="margin-bottom: 10px;" class="stop-item">
        <!-- <div style="font-size: 10px;">{{item.id}}</div> -->
        <div class="flex">
          <div class="right10">{{item.name}},</div>
          <div class="item-qty right5">{{item.qtyTxt}}</div>
          <div class="item-qty right5">{{item.totalWeight}}</div>
          <div class="item-qty right5" *ngIf="item.qtyNumer > 1">(Weight Per Unit: {{item.weightPerUnit}})</div>
          <div class="item-qty right5">{{item.size}}</div>
          <!-- <div class="left10 danger" *ngIf="item.isMissing">{{ item.status }}</div> -->
          <ng-container *ngIf="!item.itemCompleted">
            <div class="left10 clickable danger" (click)="onReportMissing(item)">Report Missing</div>
          </ng-container>
        </div>
        <div item-barcode style="white-space:pre-wrap;"
          [itemInfo]="displayInfo.scanningBarcodeResults"
          [stopItem]="item.stopItem"
          [timezone]="displayInfo.timezone"></div>
        <div class="flex">
          <ng-container *ngFor="let attachdedFile of item.attachdedFiles">
            <div class="attached-img">
              <div ngxViewer [viewerOptions]="{ transition: true }">
                <img
                      [ngClass]="{ 'active': attachdedFile.url === attachdedFile.url && attachdedFile._id === item._id}"
                      width="100px" [src]="attachdedFile.url" alt=""/>
              </div>
            </div>
          </ng-container>
        </div>
        <div *ngIf="item.driverAddedInfo">
          <span>{{item.driverAddedInfo.label}}</span>
          <a target="_blank" [href]="item.driverAddedInfo.link">{{item.driverAddedInfo.name}}</a>
          <span style="margin-left: 5px; font-size: 12px;"> - {{item.driverAddedInfo.time}}</span>
        </div>
      </div>
      <ng-container *ngIf="canAddNewItem()">
        <a (click)="onAddBarcode()">Add new item with barcode</a>
      </ng-container>
    </div>
  </div>
  <ng-container *ngIf="displayInfo?.shipment?.isContainsAlcohol">
    <span nz-icon nzType="check" nzTheme="outline"></span> Contain Alcohol
  </ng-container>
  
  <ng-container *ngIf="isShowFailedReason">
    <div class="row flex">
      Failed reason: {{ displayInfo?.failedReason ?? 'N/A' }}
    </div>
  </ng-container>
</div>
