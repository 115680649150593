<div style="color: var(--dispatch-black); font-size: 14px;" *ngIf="displayInfo"
  ngClass="navigation-item {{ isSelectedItem ? 'selected' : '' }}">
  <div class="flex" style="margin-bottom: 8px;">
    <div class="flex" style="flex: 3;font-size: 14px;">
      <i *ngIf="displayInfo.warningMessage" nz-icon nzType="warning" nzTheme="outline" class="warning" nz-tooltip
        [nzTooltipTitle]="tplWarning"></i>
      <ng-template #tplWarning>
        <div style="white-space: pre-wrap;">{{displayInfo.warningMessage}}</div>
      </ng-template>
      <div class="center-vertical font-bold">
        <span style="color: var(--dispatch-black);">{{displayInfo.codeJob}}</span>
        <span style="margin-left: 8px; font-size: 18px; color: #334155; opacity: 60%" nz-icon
          nzType="info-circle" nz-tooltip="Quick Preview Stops" nzTheme="fill"  (click)="onBtnQuickPreview()">
        </span>
         <ng-container *ngIf="pinnedNotes&&pinnedNotes.length>0">
           <div nz-popover nzPopoverTitle="Pinned Notes" [nzPopoverContent]="pinnedNotesPopover">
              <span style="margin-left: 8px; font-size: 18px; color: #FAAD14;;margin-top: 1px" nz-icon
                    nzType="pushpin" nzTheme="fill"  (click)="onBtnQuickPreviewNote()"></span>
           </div>
         </ng-container>
         <ng-container *ngIf="driverMessages&&driverMessages.hasResponseFromDriver==true">
           <div nz-popover nzPopoverTitle="Driver messages" [nzPopoverContent]="driverMessagesPopover">
              <span style="margin-left: 8px; font-size: 18px;margin-top: 3px; color: #cd4246;" nz-icon
                    nzType="message" nzTheme="fill" ></span>
           </div>
         </ng-container>
        <div *ngIf="displayInfo.onHold" nz-tooltip="Some shipments on this route are currently On Hold" class="hold-badge">HOLD</div>
      </div>
    </div>
    <div *ngIf="displayInfo.isGhostLoad" class="ghost-load-label left5">{{ displayInfo.labelGhostLoad }}</div>
    <div *ngIf="displayInfo.isLinehaul" class="linehaul-label left5">Linehaul</div>
    <div class="warp-id">
      <job-live-icon *ngIf="displayInfo.isJobInProgress" [data]="jobLiveIconData"
        [isOnline]="displayInfo.isDriverOnline"></job-live-icon>
    </div>
    <div>
      <div class="{{'job-status-' + displayInfo.statusCssClassJob}} upper font-medium" style="font-size: 14px;">
        {{displayInfo.statusValueJob}}
      </div>
    </div>
    <!-- <div class="alert-tracking">
      <div style="display: flex;" *ngIf="displayInfo?.hasTrackingIssue" class="setting-status-late" (click)="onBtnViewTrackingSettings(job)">
        <span nz-icon nzType="exclamation-circle" nzTheme="fill" nz-tooltip nzTooltipTitle="Alarms"></span>
      </div>
    </div> -->
  </div>

  <div class="flex">
    <div style="flex: 3; color: #1890ff;">{{displayInfo.label}}</div>
    <!-- <div>
      {{displayInfo.shipmentWarpId}}
      <ng-container *ngIf="displayInfo.shipmentsNumber > 1">
        <a [nzTooltipTitle]="displayInfo.shipmentsTooltip" nzTooltipPlacement="top" nz-tooltip nz-typography nzEllipsis>
          +{{ displayInfo.shipmentsNumber - 1 }}
        </a>
      </ng-container>
    </div> -->
  </div>

  <div class="flex font-regular" style="margin-bottom: 8px;">
    <img src="\assets\img\dispatch-icons\route.svg" alt="">
    <div style="margin-left: 3px;">
      {{displayInfo.startLocation}} - {{displayInfo.endLocation + " "}} {{displayInfo.countStops}}
    </div>
  </div>
  <div class="flex" style="margin-bottom: 8px; font-size: 13.5px;" [nzTooltipTitle]="detailTimePickDrop"
    nzTooltipPlacement="bottomLeft" [nzTooltipTrigger]="'hover'" nz-tooltip [nzTooltipColor]="'#334155'">
    <span class="f20" nz-icon nzType="calendar" nzTheme="outline"></span>
    <ng-container *ngIf="displayInfo.displayPick.date">
      <div class="font-bold" style="margin-left: 5px;">
        {{displayInfo.displayPick.date}}
      </div>
      <div class="font-bold" style=" margin-left: 2px;">
        {{displayInfo.displayPick.hour}} {{displayInfo.displayPick.timezone}}
      </div>
    </ng-container>
    <ng-container *ngIf="!displayInfo.displayPick.date">
      <div class="font-bold" style="margin-left: 5px; color: red">N/A</div>
    </ng-container>
    
    <div style="margin-left: 1px;">
      -
    </div>
    <ng-container *ngIf="displayInfo.displayDrop.date">
      <div class="font-bold" style="margin-left: 1px;">
        {{displayInfo.displayDrop.date}}
      </div>
      <div class="font-bold" style="margin-left: 2px;">
        {{displayInfo.displayDrop.hour}} {{displayInfo.displayDrop.timezone}}
      </div>
    </ng-container>
    <ng-container *ngIf="!displayInfo.displayDrop.date">
      <div class="font-bold" style="margin-left: 1px; color: red">N/A</div>
    </ng-container>
    <ng-template #detailTimePickDrop>
      <div style="font-weight: 400;">Pickup Time Window: {{displayInfo.pickWindow}}</div>
      <div>Delivery Time Window: {{displayInfo.dropWindow}}</div>
    </ng-template>
  </div>
  <div class="center-vertical" style="margin-bottom: 8px;">
    <img src="/assets/img/dispatch-icons/car.svg" alt="">
    <div class="flex" style="margin-left: 4px;">
      <ng-container *ngIf="!displayInfo.carrierName">
        <span style="color: red;">No Carrier</span>
      </ng-container>
      <ng-container *ngIf="displayInfo.carrierName && !displayInfo.driverName">
        <span style="color: var(--dispatch-black)">{{displayInfo.carrierName}} <ng-container *ngIf="!disable" >- <span style="color: red;"
          (click)="onBtnEditDriver()">Assign Driver</span></ng-container></span>
      </ng-container>
      <ng-container *ngIf="displayInfo.carrierName && displayInfo.driverName">
        <span style="color: var(--dispatch-black)">{{displayInfo.carrierName}} - {{displayInfo.driverName}}</span>
      </ng-container>
    </div>
  </div>

  <div class="flex">
    <div class="flex messageWarning" *ngIf="isHasTrackingTask">
      <div>
        {{displayInfo.message}}
      </div>
      <!-- <button class="btn-snooze" *ngIf="!disable && issueType === 'updateETA'" nz-button nzType="text" (click)="onBtnSnooze(job);">SNOOZE</button> -->
    </div>
    <!-- <div *ngIf="isAlert && isSnoozed" class="flex group-snoozed">
      <div class="flex group-item">
        <i class="icon snooze"></i>
        <span>SNOOZED - {{displayInfo.message}}</span>
      </div>
      <ng-container *ngIf="!disable">
        <span countdown-timer [time]="displayInfo.snoozeTime" (refreshDispatchService)="refreshDispatchService()" style="color: #1E40AF;"></span>
      </ng-container>
    </div> -->
  </div>
  <div *ngIf="isComeFromCarrierWorkingHistory && job?.assignedCarrier?.rating?.type" class="flex" style="margin-top: 5px; justify-content: flex-end;">
    <div class="text-container" id="{{job.id}}">
      <warp-when-by [model]="job?.assignedCarrier?.rating?.whenBy" [viewTemplate]="tplReviewWhenBy"></warp-when-by>
      <span *ngIf="job?.assignedCarrier?.rating?.note" class="grey f13">: {{ job.assignedCarrier.rating.note }}</span>
    </div>
    <button nz-button nzType="link" nzSize="small" class="btn-more" *ngIf="showBtnMore" (click)="onClickMore($event)">more</button>
    <div app-rating [model]="job.assignedCarrier.rating"></div>
  </div>
</div>

<!--pinned notes preview-->
<ng-template #pinnedNotesPopover>
  <div class="preview-note-box">
    <ng-container *ngFor="let item of pinnedNotes" [ngTemplateOutlet]="noteItem"
                  [ngTemplateOutletContext]="{item}"></ng-container>
  </div>
</ng-template>
<ng-template #noteItem let-item="item" >
  <div style="margin: 0px 0px 15px; width: 100%">
    <div style="display: flex;justify-content: space-between; width: 100%">
      <div style=" width: 100%">
        <div style="display: flex;width: 100%;">
          <ng-container *ngIf="isExfreight(item)">
            <nz-avatar nzIcon="user" style="margin-top: 5px" nzSize="small" nzSrc="assets/img/exfreight.png"></nz-avatar>
          </ng-container>
          <ng-container *ngIf="!isExfreight(item)">
            <nz-avatar style="margin-top: 5px" nzSize="small" [nzText]="getCarrierName(item.carrier)?.slice(0,1) || getFullName(item.user).slice(0,1)" [ngStyle]="{ 'background-color': '#7265e6' }"></nz-avatar>
          </ng-container>
          <div style="width: calc(100% - 32px);margin-left: 10px;overflow-x: hidden;">
            <div class="name semibold">
              {{getCarrierName(item?.carrier) || getFullName(item.user) || 'No name'}}
            </div>
            <div style="font-size: 12px;color: rgb(143, 143, 143);">{{item.time}}</div>
            <div class="top" style="width: 100%; margin-top: 2px;">
              <div style="line-height: 22px;word-wrap: break-word;" *ngIf="item.content" [innerText]="item.content"></div>
              <div *ngIf="item?.imageUploadFilesArr" class="list-images-upload">
                <div *ngFor="let image of item?.imageUploadFilesArr;let i = index">
                  <img *ngIf="image" (click)="viewImageItem(image.imgUrl)" class="attached-image" [src]="image.imgUrl" alt="">
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style="width: 100%; height: 1px; margin-top: 5px; background-color: #E9F2ED"></div>
      </div>
    </div>

  </div>
</ng-template>
<!--Driver messages preview-->
<ng-template #driverMessagesPopover>
  <div class="preview-note-box">
    <ng-container *ngFor="let item of driverMessages.messages" [ngTemplateOutlet]="driverMessageItem"
                  [ngTemplateOutletContext]="{item}"></ng-container>
  </div>
</ng-template>
<ng-template #driverMessageItem let-item="item">
  <div style="margin: 0px 0px 15px; width: 100%">
    <div style="display: flex;justify-content: space-between; width: 100%">
      <div style=" width: 100%">
        <div style="display: flex;width: 100%;">
          <ng-container *ngIf="isExfreight(item)">
            <nz-avatar nzIcon="user" style="margin-top: 5px" nzSize="small" nzSrc="assets/img/exfreight.png"></nz-avatar>
          </ng-container>
          <ng-container *ngIf="!isExfreight(item)">
            <nz-avatar style="margin-top: 5px" nzSize="small" [nzText]="getFullName(item.user || item.driver).slice(0,1)" [ngStyle]="{ 'background-color': '#7265e6' }"></nz-avatar>
          </ng-container>
          <div style="width: calc(100% - 32px);margin-left: 10px;overflow-x: hidden;">
            <div class="name semibold">
              {{getFullName(item.user || item.driver) || 'No name'}}
            </div>
            <div style="font-size: 12px;color: rgb(143, 143, 143);">{{item.time}}</div>
            <div class="top" style="width: 100%; margin-top: 2px;">
              <div style="line-height: 22px;word-wrap: break-word;" *ngIf="item.content" [innerText]="item.content"></div>
              <div *ngIf="item?.imageUploadFilesArr" class="list-images-upload">
                <div *ngFor="let image of item?.imageUploadFilesArr;let i = index">
                  <img *ngIf="image" (click)="viewImageItem(image.imgUrl)" class="attached-image" [src]="image.imgUrl" alt="">
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style="width: 100%; height: 1px; margin-top: 5px; background-color: #E9F2ED"></div>
      </div>
    </div>

  </div>
</ng-template>
<ng-template #tplReviewWhenBy let-hasData="hasData" let-displayInfo="displayInfo">
  <span *ngIf="hasData" class="grey f13">
    <a [routerLink]="[displayInfo.hyperLinkCreatedBy]" 
      class="grey"
      target="_blank">
      {{displayInfo.createdBy}}</a> 
    rated
  </span>
</ng-template>
