import { Component, Input, ViewChild, TemplateRef } from '@angular/core';
import { LoadingComponent } from '@app/admin/components/loading/loading.component';
import { Const } from '@const/Const';
import { BaseFormDialog1 } from '@dialogs/base-form-dlg1';
import { DialogService } from '@dialogs/dialog.service';
import { NzModalRef } from "ng-zorro-antd/modal";

const CANCEL_REASON_OPTIONS = [
  { label: 'Order not Dispensed', value: Const.OrderCancelReasons.ORDER_NOT_DISPENSED },
  { label: 'System Error', value: Const.OrderCancelReasons.SYSTEM_ERROR },
  { label: 'Carrier Cancelled', value: Const.OrderCancelReasons.CARRIER_CANCELLED },
  { label: 'Location Closed', value: Const.OrderCancelReasons.LOCATION_CLOSED },
  { label: 'Customer Cancelled', value: Const.OrderCancelReasons.CUSTOMER_CANCELLED },
  { label: 'Shipment(s) not ready', value: Const.OrderCancelReasons.SHIPMENT_NOT_READY },
  { label: 'Other', value: Const.OrderCancelReasons.OTHER },
]

@Component({
  selector: '[create-tonu-route]',
  templateUrl: './view.html',
  styleUrls: ['./style.scss']
})
export class CreateTONURouteDialog extends BaseFormDialog1 {

  public isLoading: boolean = false;
  private dlgCreateTONURouteDone: NzModalRef;
  @Input() cancelReasonOptions?: { label: string, value: string }[] = CANCEL_REASON_OPTIONS;
  @Input() onSubmitSucceeded: (resp) => void;
  @Input() jobId: any;

  @ViewChild('tplCreateTONURouteDone') tplCreateTONURouteDone: TemplateRef<any>;

  loadingRef;
  hyperLinkUrl: string = "";
  tonuRoute: any;

  constructor() {
    super();
  }

  protected formGroupDeclaration: FormGroupDeclaration = {
    carrierTONU: { label: 'Carrier TONU payment', type: 'number', required: true, placeHolder: "Enter the money" },
    customerTONU: { label: 'Customer TONU charge', type: 'number', required: true, placeHolder: "Enter the money" },
    cancelReason: { label: 'Cancel Reason', type: 'string', required: true, placeHolder: "Select Cancel Reason" },
  }

  ngOnInit() {
    super.ngOnInit();
  }

  onBtnClose() {
    this.closeDialog();
  }

  protected getFormData_JSON(isCreateNew: boolean): object {
    let json: any = super.getFormData_JSON(true); // always get full data
    return json;
  }

  onBtnCreateLoad() {
    const payload: any = this.getFormData_JSON(true);
    this.startProgress();
    this.loadingRef = DialogService.openFormDialog1(LoadingComponent, {});
    
    this.api.POST(Const.APIV2(`${Const.APIURI_JOBS}/create-tonu-route`), { jobId: this.jobId, ...payload}, { timeout: 5 * 60 * 1000 }).subscribe(
      (resp) => {
        this.stopProgress();
        this.showSuccess('TONU Route has been created successfully.');
        this.loadingRef?.close();
        this.tonuRoute = resp.data;
        this.hyperLinkUrl = `${Const.routeAdminDispatchList}/${this.tonuRoute.id}`;
        this.showDialogCreateTONURouteDone();
        this.onSubmitRequestSucceeded(resp);
      },
      (err) => {
        this.showErr(err);
        this.stopProgress();
        this.loadingRef?.close();
      }
    );
  }

  onSubmitRequestSucceeded(resp) {
    this.onSubmitSucceeded(resp);
    this.closeDialog();
  }


  private showDialogCreateTONURouteDone() {
    this.dlgCreateTONURouteDone = this.modalService.create({
      nzContent: this.tplCreateTONURouteDone,
      nzClosable: false,
      nzMaskClosable: false,
      nzCentered: true,
      nzFooter: null,
      nzWrapClassName: 'dialog-manual-load-done'
    });
  }

  closeDialogCreateTONURouteDone() {
    this.dlgCreateTONURouteDone?.close();
  }

}