<div class="form-header no-border no-padding-bottom">
  <div class="form-title f18b">Add Item</div>
  <i class="ic-close clickable" (click)="onBtnCancel()" nz-icon nzType="close" nzTheme="outline"></i>
</div>

<form class="form-detail" [formGroup]="formInput" nz-form style="padding: 26px;">
  <div nz-row [nzGutter]="[16, 16]">
    <div nz-col [nzSpan]="8" *ngIf="'qtyUnit' as key">
      <nz-form-item>
        <nz-form-control>
          <nz-select [ngClass]="key"
            nzBackdrop="true" [formControlName]="key" 
            (ngModelChange)="onModelChange(key)"
          >
            <nz-option *ngFor="let item of qtyUnits" [nzValue]="item" [nzLabel]="item"></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </div>

    <div nz-col [nzSpan]="16" *ngIf="'name' as key">
      <nz-form-item>
        <nz-form-control>
          <input nz-input [formControlName]="key" [ngClass]="key"
            [type]="getInputType(key)" 
            [placeholder]="getPlaceHolder(key)"
            (input)="onInputChanged($event, key)" 
            (keypress)="onInputKeyPress($event, key)"
          >
        </nz-form-control>
      </nz-form-item>
    </div>

    <div nz-col [nzSpan]="24">
      <div class="flex group-fields">
        <div *ngFor="let key of ['length', 'width', 'height', 'sizeUnit']; let i = index;" class="group-fields-item">
          <nz-form-item>
            <nz-form-control class="{{key}}">
              <ng-container [ngSwitch]="key">
                <nz-select 
                  *ngSwitchCase="'sizeUnit'" 
                  [ngClass]="key" nzBackdrop="true" 
                  [formControlName]="key"
                >
                  <nz-option *ngFor="let item of sizeUnits" [nzValue]="item" [nzLabel]="item"></nz-option>
                </nz-select>

                <input *ngSwitchDefault [ngClass]="key"
                  nz-input [formControlName]="key"
                  [type]="getInputType(key)"
                  [placeholder]="getPlaceHolder(key)"
                  (input)="onInputChanged($event, key)"
                  (keypress)="onInputKeyPress($event, key)"
                >
              </ng-container>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
    </div>

    <div nz-col [nzSpan]="24">
      <div class="flex group-fields">
        <div *ngFor="let key of ['weightPerUnit', 'weightUnit', 'weight']; let i = index;" class="group-fields-item">
          <nz-form-item>
            <nz-form-control class="{{key}}">
              <ng-container [ngSwitch]="key">
                <nz-select *ngSwitchCase="'weightUnit'" 
                  [ngClass]="key" nzBackdrop="true" 
                  [formControlName]="key"
                >
                  <nz-option *ngFor="let item of weightUnits" [nzValue]="item" [nzLabel]="item"></nz-option>
                </nz-select>

                <input *ngSwitchDefault [ngClass]="key"
                  nz-input [formControlName]="key"
                  [type]="getInputType(key)"
                  [placeholder]="getPlaceHolder(key)"
                  (input)="onInputChanged($event, key)"
                  (keypress)="onInputKeyPress($event, key)"
                >
              </ng-container>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
    </div>

    <div nz-col [nzSpan]="24" *ngIf="'barcode' as key">
      <nz-form-item>
        <nz-form-control>
          <input nz-input [formControlName]="key" 
            [ngClass]="key"
            [type]="getInputType(key)" 
            [placeholder]="getPlaceHolder(key)"
            (input)="onInputChanged($event, key)" 
            (keypress)="onInputKeyPress($event, key)"
          >
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>
</form>

<div form-footer class="no-border"
  [canClickOK]="needUpdate" 
  [canClickCancel]="true"
  nzIconOK="save" labelOK="Save" 
  (onOK)="onBtnSave()"
  (onCancel)="onBtnCancel()"
></div>
