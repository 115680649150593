<div>
  <nz-checkbox-wrapper style="width: 600px;">
    <div nz-row>
      <div nz-col nzSpan="24">
        <label nz-checkbox [nzValue]="checkedAllPods" [(ngModel)]="checkedAllPods"
          (ngModelChange)="onCheckAllPods($event)" [nzIndeterminate]="indeterminatePods">Check All</label>
      </div>
    </div>
    <div nz-row>
      <div nz-col nzSpan="12">
        <label class="no-margin" *ngFor="let task of getTask('PICKUP')" nz-checkbox [(ngModel)]="selecteds[task.id]" (ngModelChange)="refreshCheckAllPods()">
          {{task.type}} {{shipmentCodeText(task?.shipment)}}</label>
      </div>
      <div nz-col nzSpan="12">
        <label class="no-margin" *ngFor="let task of getTask('DROPOFF')" nz-checkbox [(ngModel)]="selecteds[task.id]" (ngModelChange)="refreshCheckAllPods()">
          {{task.type}} {{shipmentCodeText(task?.shipment)}}</label>
      </div>
      <div nz-col nzSpan="24">
        <label class="no-margin" *ngFor="let task of getTask('RETURN')" nz-checkbox [(ngModel)]="selecteds[task.id]" (ngModelChange)="refreshCheckAllPods()">
          {{task.type}} {{shipmentCodeText(task?.shipment)}}</label>
      </div>
    </div>
  </nz-checkbox-wrapper>
  <div class="button-submit">
    <button nz-button nzType="primary" [nzLoading]="(isSubmitting)" (click)="onSubmit()"
      [nzDanger]="!checkedAllPods && !indeterminatePods">{{getBtnText()}}</button>
  </div>
</div>