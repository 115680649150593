import { Component, Input, Output } from "@angular/core";

import { BaseFormDialog1 } from "@dialogs/base-form-dlg1";

@Component({
    selector: 'snooze-form',
    templateUrl: './index.html',
    styleUrls: ['./index.scss']
    })
export class SnoozeForm extends BaseFormDialog1 {
    @Input() job: any;
    @Input() trackingTaskName: any;

    public onOk: (data) => void = () => {}

    constructor() {
        super();
    }

    preset = [

        { label: '1 Minute', value: 1 },
        { label: '5 Minute', value: 5 },
        { label: '10 Minute', value: 10 },
        { label: '15 Minute', value: 15 },
        { label: '20 Minute', value: 20 },
        { label: '30 Minute', value: 30 },
        { label: '45 Minute', value: 45 },
        { label: '1 Hour', value: 1 * 60 },
        { label: '2 Hours', value: 2 * 60 },
    ]

    
    protected formGroupDeclaration: FormGroupDeclaration = {
        hours: { label: 'Hours', placeHolder: 'hh'},
        minutes: { label: 'Minutes', placeHolder: 'mm'},
        note: {label: 'Note', placeHolder: 'Enter note here...'},
    }
    ngOnInit(): void {
        super.ngOnInit();
    }
    
    onBtnSave(): void {
        const formData = this.getFormData_JSON(true);
        const hours = formData.hours ?? 0;
        const minutes = formData.minutes ?? 0;
        let totalMinute = hours * 60 + minutes;
        if (!totalMinute) {
            this.showErr('Snooze Time is required.');
            return;
        }
        if (totalMinute > 120) {
            this.showErr('Snooze Time is too large. Maximum Snooze Time is 2 hours')
            return;
        }
        this.updateSuccess(formData);
        this.closeDialog();
    }
    generateRange(start: number, end: number): number[] {
        return Array.from({ length: end - start + 1 }, (_, i) => start + i);
      }
    setPreset(value: number) {
        this.setItemValue('hours', Math.floor(value / 60));
        this.setItemValue('minutes', value % 60);
    }
}