import { BaseComponent } from "@abstract/BaseComponent";
import { Component, Input } from "@angular/core";
import { Const } from "@const/Const";
import { DateUtil } from "@services/date-utils";
import { Utils } from "@services/utils";
import { DialogService } from "@dialogs/dialog.service";
import { TrackingSettingsDialog } from "@app/admin/shipments/tracking-settings/dialog";
import { WarpId } from "@wearewarp/universal-libs";
import { SnoozeForm } from "../snooze";
import { QuickPreview } from "../quick-preview";
import { DispatchAssignCarrierAndDriver } from "../assign-carrier";
import { ActivatedRoute } from "@angular/router";
import { DispatchService } from "../../dispatchService";
import StopEntity from "../../entity/StopEntity";
import { ModalHelper } from "@wearewarp/ng-antd";
import { NoteQuickPreview } from '@app/admin/dispatch/components/notes-quick-preview';
import { Const as WarpConst } from "@wearewarp/universal-libs";
import { JobLiveIconData } from "../../interface";
import { FromRatingDialog } from '@app/admin/components/rating/rate-dlg';


@Component({
  selector: '[list-job-item]',
  templateUrl: './index.html',
  styleUrls: ['index.scss'],
})
export class ListJobItem extends BaseComponent {

  isLoadingUpdateETA = false;

  private _job;
  @Input() get job() { return this._job }

  set job(value) {
    this._job = value;
    this.processJobItem(value);
  }

  private _pinnedNotes;
  @Input() get pinnedNotes() { return this._pinnedNotes }

  set pinnedNotes(value) {
    this._pinnedNotes = value;
  }

  private _driverMessages;
  @Input() get driverMessages() { return this._driverMessages }

  set driverMessages(value) {
    this._driverMessages = value;
  }

  @Input() isSelectedItem = false;
  @Input() disable = false;
  @Input() isComeFromCarrierWorkingHistory = false;
  public displayInfo;

  public jobLiveIconData: JobLiveIconData;

  ngOnInit(): void {
    super.ngOnInit();
  }

  constructor(
    protected activatedRoute: ActivatedRoute,
    protected dispatchService: DispatchService,
    protected modalHelper: ModalHelper,
  ) {
    super(activatedRoute)
  }

  ngOnChanges(): void {
  }
  private processJobItem(job) {
    let displayInfo: any = {};
    const firstTask = this.getFirstTask(job);
    const lastTask = this.getLastTask(job);
    const firstStop = job.stops?.length ? job.stops[0] : null
    const lastStop = job.stops?.length ? job.stops[job.stops.length - 1] : null
    displayInfo.isGhostLoad = job.type === WarpConst.JobType.ghost;
    displayInfo.isLinehaul = job.type === WarpConst.JobType.linehaul;
    displayInfo.labelGhostLoad = this.getLabelGhostLoad(job);
    displayInfo.shipmentsNumber = job.shipments?.length ?? 0;
    displayInfo.warningMessage = (job.warningArr || []).join('\n');
    displayInfo.label = this.getJobLabel(job);
    displayInfo.isMultiStop = job.tasks?.length > 2 ? true : false;
    displayInfo.isJobInProgress = job.status == Const.JobStatus.inProgress;
    displayInfo.dispatcherName = this.getDispatcher(job);
    displayInfo.carrierName = this.getCarrierName(job.carrier);
    displayInfo.driverName = this.getDriverName(job.driver);
    displayInfo.startLocation = this.getLocation(firstTask) ?? this.getLocation(firstStop);
    displayInfo.endLocation = this.getLocation(lastTask) ?? this.getLocation(lastStop);
    displayInfo.statusOfStartLocation = this.getStatusOfTask(firstTask);
    displayInfo.statusOfEndLocation = this.getStatusOfTask(lastTask);

    displayInfo.statusCssClass_startLocation = this.getStatusCssClass(displayInfo.statusOfStartLocation);
    displayInfo.statusValue_startLocation = this.showStatusValue(displayInfo.statusOfStartLocation);
    displayInfo.statusCssClass_endLocation = this.getStatusCssClass(displayInfo.statusOfEndLocation);
    displayInfo.statusValue_endLocation = this.showStatusValue(displayInfo.statusOfEndLocation);
    displayInfo.codeJob = job.code;
    displayInfo.statusValueJob = this.getStatusValueJob(job.status);
    displayInfo.statusCssClassJob = this.getStatusCssClassJob(job.status);
    displayInfo.displayPick = this.displayTimeWindowForTask(firstTask) ?? 'N/A';
    displayInfo.displayDrop = this.displayTimeWindowForTask(lastTask) ?? 'N/A';
    displayInfo.countStops = this.countStops(job);
    displayInfo.message = this.getMessage();
    displayInfo.snoozeTime = this.getSnoozeTime();


    displayInfo.pickWindow = this.displayWindow(Object.assign({ time: job.earliest_time, timeTo: job.latest_time }, job.pickDt));
    displayInfo.dropWindow = this.displayWindow(job.dropDt);
    displayInfo.isPickETA = this.checkIsETAOfLocation(firstTask);
    displayInfo.isDropETA = this.checkIsETAOfLocation(lastTask);
    displayInfo.onHold = job.shipments.filter(it => it.tags && it.tags.indexOf('HOLD') >= 0).length > 0 || (job.tags?.length && job.tags.indexOf('HOLD') >= 0)

    if (job.isPickETA && job?.pickDt?.timezone) {
      const pickEtaTime = { ...job.pickDt, time: firstTask?.etaTime?.from, timeTo: firstTask?.etaTime?.to };
      displayInfo.pickEtaTime = this.displayWindow(pickEtaTime);
    }
    if (job.isDropETA && job?.dropDt?.timezone) {
      const dropEtaTime = { ...job.dropDt, time: lastTask?.etaTime?.from, timeTo: lastTask?.etaTime?.to };
      displayInfo.dropEtaTime = this.displayWindow(dropEtaTime);
    }
    if (Utils.isArrayNotEmpty(job.shipments)) {
      const shipmentWarpIds = job.shipments.map(item => WarpId.showShipmentCode(item));
      shipmentWarpIds.sort();
      displayInfo.shipmentWarpId = shipmentWarpIds[0];
      displayInfo.hasTrackingIssue = job.shipments.find(item => item?.hasTrackingIssue === true) ? true : false
      shipmentWarpIds.shift()
      displayInfo.shipmentsTooltip = shipmentWarpIds.join(', ');
    }
    if (job.lastUpdateLocation) {
      const lastUpdateLocation = (Date.now() - DateUtil.isoDate(job.lastUpdateLocation.when).getTime()) / 1000;
      displayInfo.isDriverOnline = lastUpdateLocation <= 60; // nếu vừa mới update location cách đây không quá 1 phút thì được coi là đnag online
    }
    this.displayInfo = displayInfo;

    this.jobLiveIconData = {
      jobId: job?.id ?? '',
      status: job?.status ?? Const.JobStatus.created,
      isActive: job?.assignedDriver?.isActive ?? false,
      driverId: job?.assignedDriver?.driverId ?? '',
    }
  }

  getJobLabel(job) {
    let str = job.clients?.[0]?.name;
    if (job.clients?.length > 1) {
      str += ` (+${job.clients.length - 1})`;
    }
    return str;
  }

  // time, timeTo are ISO string 2022-03-29T18:19:10.000Z
  private displayWindow(obj: { time: string, timeTo: string, timezone: string, isAppointment: boolean }) {
    if (!obj) return 'N/A';
    let formatDateTime = Const.FORMAT_GUI_DATETIME_V3;
    let timeFrom = obj.time;
    let timeTo = obj.timeTo;
    timeFrom = DateUtil.displayLocalTime(timeFrom, { timezone: obj.timezone, format: formatDateTime });
    timeTo = DateUtil.displayLocalTime(timeTo, { timezone: obj.timezone, format: formatDateTime });
    let arr1 = timeFrom.split(',');
    let arr2 = timeTo.split(',');
    let isSameDay = arr1[0] == arr2[0];
    if (isSameDay) {
      timeTo = arr2[1]?.trim();
    }
    let str = `${timeFrom || 'N/A'} - ${timeTo || 'N/A'}`;
    let tzShort = DateUtil.timezoneStandardToUsShort(obj.timezone);
    if (tzShort) {
      str += ` ${tzShort}`;
    }
    return str;
  }

  private getFirstTask(job) {
    if (!Utils.isArrayNotEmpty(job?.taskIds) || !Utils.isArrayNotEmpty(job?.tasks)) return null;
    const firstTaskId = job.taskIds[0];
    return job.tasks.find(task => task.id == firstTaskId);
  }

  private getLastTask(job) {
    if (!Utils.isArrayNotEmpty(job?.taskIds) || !Utils.isArrayNotEmpty(job?.tasks)) return null;
    const lastTaskId = job.taskIds[job.taskIds.length - 1];
    return job.tasks.find(task => task.id == lastTaskId);
  }

  private getLocation(task) {
    if (!task) return null;
    const address = task.address || task.addr || task.info?.addr;
    return `${address?.city}, ${address?.state}`;
  }

  private getStatusOfTask(task) {
    if (!task) return null;
    return task?.status || Const.TaskStatus.created;
  }

  private checkIsETAOfLocation(task): boolean {
    if (!task) return false;
    return task?.etaTime && task?.etaTime?.from && task?.etaTime?.to;
  }

  public getDispatcher(job) {
    if (job.pic) {
      let str = this.getFullName(job.pic);
      if (str.length > 10) {
        return str.substring(0, 10) + '.';
      }
      return str;
    }
    return ''
  }
  public showStatusValue(status) {
    switch (status) {
      case Const.TaskStatus.created: return 'Not Started';
      case Const.TaskStatus.enroute: return 'Enroute';
      case Const.TaskStatus.arrived: return 'Arrived';
      case Const.TaskStatus.succeeded: return 'Succeeded';
      case Const.TaskStatus.failed: return 'Failed';
      case Const.TaskStatus.canceled: return 'Failed';
      case Const.TaskStatus.pickupFailed: return 'Removed';
      default: return 'Not Started';
    }
  }

  public getStatusCssClass(status): string {
    switch (status) {
      case Const.TaskStatus.created: return 'created';
      case Const.TaskStatus.enroute: return 'enroute';
      case Const.TaskStatus.arrived: return 'arrived';
      case Const.TaskStatus.succeeded: return 'succeeded';
      case Const.TaskStatus.failed: return 'failed';
      case Const.TaskStatus.canceled: return 'failed';
      case Const.TaskStatus.pickupFailed: return 'removed';
      default: return 'created';
    }
  }

  onBtnViewTrackingSettings(job) {
    if (!Utils.isArrayNotEmpty(job?.shipments)) return;
    let shipments = this.makeOrder(job.shipments);
    DialogService.openDialog(TrackingSettingsDialog, {
      nzComponentParams: {
        shipments
      },
      nzClassName: 'modal-xl',
      nzCentered: true,
    })
  }

  makeOrder(shipments) {
    let data = [];
    for (let order of shipments) {
      if (!order.isMultiStop) data.push(order);
      else {
        const { deliveryInfos, trackingSettings, issues } = order;
        let obj = {}
        for (let delivery of deliveryInfos) {
          if (!obj[delivery.id]) obj[delivery.id] = {}
          if (!obj[delivery.id]['_id']) obj[delivery.id]['_id'] = delivery.id;
          if (!obj[delivery.id]['parentId']) obj[delivery.id]['parentId'] = order._id;
          if (!obj[delivery.id]['isMultiStop']) obj[delivery.id]['isMultiStop'] = order.isMultiStop;
          if (!obj[delivery.id]['trackingSettings']) obj[delivery.id]['trackingSettings'] = trackingSettings;
          if (!obj[delivery.id]['issues']) obj[delivery.id]['issues'] = issues;
          if (!obj[delivery.id]['deliveryInfos']) obj[delivery.id]['deliveryInfos'] = deliveryInfos.filter(item => item.id === delivery.id);
        }
        data = [...data, ...Object.values(obj)];
      }
    }
    return data;
  }

  get isAlert(): boolean {
    let status = this.getIssueAlert()?.status;
    return [WarpConst.IssueStatus.alert, WarpConst.IssueStatus.snoozed].includes(status);
  }

  get isSnoozed() {
    return this.getIssueAlert()?.status === WarpConst.IssueStatus.snoozed;
  }

  getIssueAlert() {
    return this.job?.issues?.filter(issue => [WarpConst.IssueStatus.alert, WarpConst.IssueStatus.snoozed].includes(issue.status))[0];
  }

  getSnoozeTime() {
    if (!this.getIssueAlert()) return;
    return this.getIssueAlert()?.notifyTime;
  }

  get issueType() {
    return this.getIssueAlert()?.type;
  }

  get isHasTrackingTask() {
    return this.job?.listTrackingTasks?.length > 0;
  }

  getTrackingTaskText(item) {
    if (!item?.type) return ''
    return Const.JobTrackingTaskConfig[item.type]?.label || '';
  }

  getMessage() {
    let trackingTask;
    for (let type of Const.TrackingTasksPriorityArr) {
      let tmp = this.job?.listTrackingTasks?.find(it => it.type === type);
      if (tmp) {
        trackingTask = tmp;
        break;
      }
    }
    return this.getTrackingTaskText(trackingTask) || '';
  }

  getStatusValueJob(status) {
    switch (status) {
      case Const.JobStatus.created: return 'Created';
      case Const.JobStatus.inProgress: return 'In Progress';
      case Const.JobStatus.completed: return 'Completed';
      case Const.JobStatus.canceled: return 'Canceled';
      default: return 'Created';
    }
  }

  getStatusCssClassJob(status) {
    switch (status) {
      case Const.JobStatus.created: return 'created';
      case Const.JobStatus.inProgress: return 'inProgress';
      case Const.JobStatus.completed: return 'completed';
      case Const.JobStatus.canceled: return 'canceled';
      default: return 'created';
    }
  }

  displayTimeWindowForTask(task) {
    let formatDateTime = "MM/DD/YYYY HH:mm";
    let timezone = DateUtil.timezoneStandardToUsShort(task?.address?.metadata?.timeZoneStandard);
    const from = task?.info?.appointmentInfo?.from || task?.info?.windows?.[0]?.from;
    if (from) {
      let time = DateUtil.displayLocalTime(from, {
        timezone: task?.address?.metadata?.timeZoneStandard,
        format: formatDateTime,
      });
      let date = time.split(' ')[0];
      let hour = time.split(' ')[1];
      return {
        date: date,
        hour: hour,
        timezone: timezone
      }
    } else {
      return;
    }
  }

  async refreshDispatchService() {
    if (this.job?.id == this.dispatchService.getRoute()?.getId()) {
      this.dispatchService.refresh();
      return;
    }
    const dispatchService = new DispatchService(this.api);
    await dispatchService.setRouteId(this.job.id ?? this.job._id).refresh();
    this.job = dispatchService.getRoute().getData();
  }

  onBtnSnooze(job) {
    event.preventDefault();
    event.stopPropagation();
    DialogService.openFormDialog1(SnoozeForm, {
      nzComponentParams: {
        job: job,
        closeOnSuccess: true,
        updateSuccess: async (data) => {
          //convert data to minutes
          const snoozeTime = data.hours * 60 + data.minutes;
          data = {
            snoozeTime: snoozeTime,
            stopId: this.getIssueAlert()?.stopId,
          };
          this.api.POST(
            `${Const.APIV2(Const.APIURI_JOBS)}/${this.job.id}/setup_snooze`,
            data
          ).subscribe(async (resp) => {
            this.refreshDispatchService()
          })
        },
      },
      nzClassName: 'modal-sm modal-snooze',
      nzCentered: true,
    })
  }
  onBtnQuickPreview() {
    event.preventDefault();
    event.stopPropagation();
    DialogService.openFormDialog1(QuickPreview, {
      nzClassName: "modal-lg",
      nzComponentParams: {
        job: this.job,
      }
    })
  }

  onBtnQuickPreviewNote() {
    event.preventDefault();
    event.stopPropagation();
    DialogService.openFormDialog1(NoteQuickPreview, {
      nzClassName: "modal no-padding",
      nzComponentParams: {
        jobId: this.job.id,
        jobCode: this.job.code,
      }
    })
  }

  onBtnAssignCarrier() {
    event.preventDefault();
    event.stopPropagation();
    DialogService.openFormDialog1(DispatchAssignCarrierAndDriver, {
      nzComponentParams: {
        jobId: this.job._id,
        jobCode: this.job?.code,
        action: 'assign-carrier',
        closeOnSuccess: true,
        updateSuccess: async (resp) => {
          this.refreshCarrierAndDriverInfo();
        }
      },
      nzClassName: 'modal-no-padding assign-carrier-form',
    });
  }
  async onBtnEditDriver() {
    event.preventDefault();
    event.stopPropagation();

    DialogService.openFormDialog1(DispatchAssignCarrierAndDriver, {
      nzComponentParams: {
        jobId: this.job._id,
        jobCode: this.job?.code,
        action: 'assign-driver',
        vehicle: this.job?.requiredVehicle,
        closeOnSuccess: true,
        updateSuccess: async (resp) => {
          this.refreshCarrierAndDriverInfo();
        }
      },
      nzClassName: 'modal-no-padding assign-carrier-form',
    });
  }
  async refreshCarrierAndDriverInfo() {
    const dispatchService = new DispatchService(this.api);
    await dispatchService.setRouteId(this.job.id ?? this.job._id).refresh();
    const job = dispatchService.getRoute().getData();
    this.job = {
      ...this.job,
      assignedCarrier: job?.assignedCarrier,
      carrier: job?.carrier,
      carrierId: job?.carrierId,
      assignedDriver: job?.assignedDriver,
      driver: job?.driver,
      driverId: job?.driverId
    }
    this.dispatchService.refresh();
  }

  countStops(job) {
    return job.stops?.length ? `(${job.stops.length} Stops)` : '';
  }
  getStopNeedUpdateEta(job) {
    const listStatus = new Map([
      [Const.TaskStatus.created, 'Created'],
      [Const.TaskStatus.enroute, 'Enroute'],
      [Const.TaskStatus.arrived, 'Arrived'],
    ])
    const task = job.tasks.find(task => !task.etaTime);
    if (!task) return;
    const stop = job.stops?.find(stop => listStatus.has(stop.status) && stop.taskIds.includes(task.id));
    if (!stop) return;
    return stop;
  }

  getFirstTaskUpdateETA(listTask) {
    return listTask?.find(task => !task.etaTime && [Const.TaskStatus.created, Const.TaskStatus.enroute, Const.TaskStatus.arrived].includes(task.status));
  }

  stopUpdateETA: StopEntity;
  async getStopUpdateETA(job) {
    const dispatchService = new DispatchService(this.api);
    await dispatchService.setRouteId(job.id ?? job._id).refresh();
    const route = dispatchService.getRoute();
    const stops = route?.getStops();
    const stop = stops?.filter((stop) =>
      stop?.getTasks()?.find((task) =>
        [Const.TaskStatus.arrived, Const.TaskStatus.created, Const.TaskStatus.enroute].includes(<any>task.getStatus()) && !task.getETA()
      )
    );
    this.isLoadingUpdateETA = true;
    if (!stop?.length) {
      this.stopUpdateETA = null;
      return;
    };
    this.stopUpdateETA = stop[0];
    return stop[0].getIndex() + 1;
  }

  /**
   * Pinned note process
   */
  viewImageItem(imgUrl) {
    if (!imgUrl) return;
    DialogService.previewImgs([imgUrl], 0);
  }

  public getCarrierName(carrier): string {
    let name = super.getCarrierName(carrier);
    if (name) name += ' (Carrier)';
    return name;
  }

  public isExfreight(note) {
    return note?.insert?.byCarrierId == Const.CarrierId_Exfreight;
  }

  getLabelGhostLoad(job) {
    return job.type === WarpConst.JobType.ghost && job.source == WarpConst.JobSources.marketplace ? 'Marketplace' : 'Ghost Load';
  }

  onClickMore(event: PointerEvent) {
    event.preventDefault();
    this.modalHelper.open(FromRatingDialog, {
      nzTitle: "Review Infomation",
      nzClosable: false,
      labelBtnOK: 'Confirm',
      labelBtnCancel: 'Cancel',
      nzComponentParams: {
        viewMode: true,
        type: this.job.assignedCarrier.rating.type,
        model: this.job.assignedCarrier.rating,
      },
    });
  }

  get showBtnMore() {
    let el = document.getElementById(this.job.id)
    if (el?.offsetHeight < el?.scrollHeight || el?.offsetWidth < el?.scrollWidth) {
      el.classList.add('text-overflow');
      return true;
    }
    return false;
  }
}
