import { Image as ImageConstructor } from 'image-js';

export class ImageService {
  // Không để input là url do đã chặn request trực tiếp tới s3
  static async compareImages(imgs: ArrayBuffer[]) {
    const images: ImageConstructor[] = await Promise.all(imgs.map(async (img) => {
      return await ImageConstructor.load(img);
    }))
   
    const compare_result = [];
    for (let i = images.length - 1; i > 0; i--) {
      compare_result[i] = [];
      for (let j = i - 1; j >= 0; j--) {
        if (!images[i] && !images[j]) {
          compare_result[i][j] = 0;
          continue;
        }
        const result = images[j].getSimilarity(images[i], {channels: [0], average: true});
        compare_result[i][j] = result;
      }
    }
    return compare_result;
  }
}